import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const [activities, setActivities] = useState([
    "User1 created account",
    "User2 updated profile details",
  ]);
  const navigate = useNavigate();
  useEffect(() => {});

  const handleManageAccounts = () => {
    navigate("/manageAccount");
  };

  return (
    <div className="text-black min-w-screen min-h-screen p-10">
      <h1 className="font-bold text-4xl">Admin Dashboard</h1>
      <div className="flex justify-center space-x-4 text-center pt-5">
        <button className="px-4 py-2 bg-slate-200 rounded-lg">
          Create New Account
        </button>
        <button
          className="px-4 py-2 bg-slate-200 rounded-lg"
          onClick={handleManageAccounts}
        >
          Manage Accounts
        </button>
        <button className="px-4 py-2 bg-slate-200 rounded-lg">
          View Transactions
        </button>
        <button className="px-4 py-2 bg-slate-200 rounded-lg">Settings</button>
      </div>

      <div className="font-bold text-2xl mt-12">My Recent Activities</div>
      <div>
        <div className="relative overflow-x-auto mt-4">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-b">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Activity
                </th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity, id) => (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  key={id}
                >
                  <td className="px-6 py-4">{activity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
