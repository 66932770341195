import React, { useState } from 'react';

const ManageAccounts = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null); // State to track selected account
  const accounts = [
    { id: 1234, client: 'John Doe' },
    { id: 5678, client: 'Jane Smith' },
    { id: 9101, client: 'Alan Turing' }
  ];

  const handleSearch = () => {
    console.log('Searching for:', searchTerm);
  };

  const handleAddNewAccount = () => {
    console.log('Adding new account');
  };

  const handleSelectAccount = (account) => {
    setSelectedAccount(account);
  };

  const handleEdit = () => {
    if (selectedAccount) {
      console.log('Editing account:', selectedAccount);
    } else {
      console.log('No account selected');
    }
  };

  const handleDelete = () => {
    if (selectedAccount) {
      console.log('Deleting account:', selectedAccount);
    } else {
      console.log('No account selected');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 w-full">
      <div className="w-full max-w-5xl bg-white p-8 shadow-lg rounded-lg h-full">
        <h2 className="text-3xl font-bold mb-6 text-gray-800">Manage Accounts</h2>

        {/* Search Section */}
        <div className="mb-6">
          <label htmlFor="search" className="block text-gray-700 text-sm font-medium mb-2">
            Search:
          </label>
          <input
            id="search"
            type="text"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="flex space-x-4 mb-6">
          <button
            onClick={handleSearch}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
          >
            Search
          </button>
          <button
            onClick={handleAddNewAccount}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-colors"
          >
            Add New Account
          </button>
        </div>

        {/* Account List Section */}
        <h3 className="text-xl font-semibold mb-3 text-gray-700">Account List:</h3>
        <ul className="bg-gray-50 p-4 border border-gray-200 rounded h-full overflow-auto">
          {accounts.map((account) => (
            <li
              key={account.id}
              onClick={() => handleSelectAccount(account)}
              className={`py-4 px-4 mb-4 bg-gray-100 border border-gray-200 rounded cursor-pointer ${selectedAccount?.id === account.id ? 'bg-blue-100' : ''}`}
            >
              <div className="mb-2">
                <span className="font-medium">Account ID:</span> {account.id},{' '}
                <span className="font-medium">Client:</span> {account.client}
              </div>
            </li>
          ))}
        </ul>

        {/* Buttons below the account list */}
        <div className="flex space-x-4 mt-6">
          <button
            onClick={handleEdit}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
          >
            Edit
          </button>
          <button
            onClick={handleDelete}
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageAccounts;
