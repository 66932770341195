import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { COGNITO_LOGIN_URL, API_URL } from "../constants";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const [cognitoCode, setCognitoCode] = useState("");
  const [error, setError] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const getTokens = async (code) => {
    try {
      // const response = await axios.post(`${API_URL}/auth`, {
      //   authorization_code: code,
      // });
      const response = {
        data: {},
        status: 403,
      };
      if (
        response.data.access_token == null ||
        response.data.refresh_token == null
      ) {
        console.log("Error in getting tokens");
        setError("Error in getting tokens");
        return;
      }
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);

      setAccessToken(response.data.access_token);
    } catch (error) {
      setError("Error in getting tokens");
      console.log(error);
    }
  };

  const getUsersProfile = async () => {
    try {
      const decoded = jwtDecode(accessToken); // decoding the access token to get the user details
      const profile = decoded["cognito:groups"][0];
      // if (profile === "admin") navigate("/admin");
      // else navigate("/agent");
    } catch (error) {
      setError("Error in getting user details");
      console.log(error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code && accessToken.length == 0) {
      setCognitoCode(code);
      getTokens(code); // making a request to the backend to get the access token
    }
  }, []);

  useEffect(() => {
    if (accessToken.length > 0) {
      getUsersProfile(); // getting the user details from the access token
    }
  }, [accessToken]);

  const handleLogin = () => {
    window.location.href = COGNITO_LOGIN_URL; // routing to cognito login page
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-white">
      <div className="border border-gray-700 p-8 text-gray-800 bg-white w-80 text-center font-mono">
        <h2 className="border-b border-gray-700 pb-4 mb-4">CRM Login</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <button
          onClick={handleLogin}
          className="w-full p-2 bg-gray-700 text-white border border-gray-700 hover:bg-gray-600 transition-colors"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
