import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
  const [activities, setActivities] = useState([
    "Created profile for Client1",
    "Updated profile for Client2",
  ]);
  const navigate = useNavigate();
  useEffect(() => {});

  const handleCreateClientProfile = () => {
    navigate("/createClient");
  };

  const handleViewTransactions = () => {
    navigate("/transactions");
  };

  useEffect(() => {
    // fetching access token from local storage
    const accessToken = localStorage.getItem("accessToken");
    console.log(accessToken);
    if (accessToken == null) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="text-black min-w-screen min-h-screen p-10">
      <h1 className="font-bold text-4xl">Agent Dashboard</h1>
      <div className="flex justify-center space-x-4 text-center pt-5">
        <button
          className="px-4 py-2 bg-slate-200 rounded-lg"
          onClick={handleCreateClientProfile}
        >
          Create Client Profile
        </button>
        <button className="px-4 py-2 bg-slate-200 rounded-lg">
          Manage Profiles
        </button>
        <button
          className="px-4 py-2 bg-slate-200 rounded-lg"
          onClick={handleViewTransactions}
        >
          View Transactions
        </button>
      </div>

      <div className="font-bold text-2xl mt-12">My Recent Activities</div>
      <div>
        <div className="relative overflow-x-auto mt-4">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-b">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Activity
                </th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity, id) => (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  key={id}
                >
                  <td className="px-6 py-4">{activity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
