import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AgentDashboard from "./Pages/AgentDashboard";
import CreateClientProfileForm from "./Pages/CreateClientProfile";
import Transactions from "./Pages/Transactions";
import Login from "./Pages/Login";
import AdminDashboard from "./Pages/AdminDashboard";
import ManageAccount from "./Pages/ManageAccount";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/agent" element={<AgentDashboard />} />
        <Route path="/createClient" element={<CreateClientProfileForm />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/manageAccount" element={<ManageAccount />} />
      </Routes>
    </Router>
  );
}

export default App;
