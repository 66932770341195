import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Transactions = () => {
  const [transactions, setTransactions] = useState([
    {
      id: 1001,
      amount: "$500",
      status: "Completed",
    },
    {
      id: 1002,
      amount: "$300",
      status: "Pending",
    },
    {
      id: 1003,
      amount: "$1000",
      status: "Failed",
    },
  ]);
  const navigate = useNavigate();
  useEffect(() => {});

  return (
    <div className="text-black min-w-screen min-h-screen p-10">
      <h1 className="font-bold text-4xl">View Transactions</h1>

      <div className="mt-10">
        <div className="flex">
          <span>Search:</span>
          <input className="ml-5 bg-slate-200 w-1/3 rounded-lg" />
        </div>

        <div>
          <div className="mt-10">Transaction List:</div>

          <div class="relative overflow-x-auto mt-4">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Transaction ID
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction) => (
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {transaction.id}
                    </th>
                    <td class="px-6 py-4">{transaction.amount}</td>
                    <td class="px-6 py-4">{transaction.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex mt-20">
          <button className="px-4 py-2 bg-slate-200 rounded-lg">
            View Details
          </button>
          <button className="ml-5 px-4 py-2 bg-slate-200 rounded-lg">
            Retry
          </button>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
